import * as $ from "jquery";
import "slick-carousel";
import * as Hammer from "hammerjs";
import "select2";

// Smooth scroll implementation
function smoothScroll(target: HTMLElement, duration: number) {
  const targetPosition = target.getBoundingClientRect().top + window.scrollY;
  const startPosition = window.scrollY;
  const distance = targetPosition - startPosition;
  let startTime: number | null = null;

  function animation(currentTime: number) {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = ease(timeElapsed, startPosition, distance, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animation);
  }

  function ease(t: number, b: number, c: number, d: number) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  requestAnimationFrame(animation);
}

// Update active state function for skills slider
function updateActiveState(
  targetItem: HTMLElement,
  items: NodeListOf<Element>,
  btns: NodeListOf<Element>
) {
  items.forEach((item) => item.classList.remove("active"));
  btns.forEach((btn) => btn.classList.remove("active"));
  targetItem.classList.add("active");
  const activeButton = Array.from(btns).find(
    (btn) => btn.getAttribute("data-target") === targetItem.id
  );
  activeButton?.classList.add("active");
}

$(document).ready(() => {
  const navigationMenuBtn = document.getElementById("header-navigation-menu");
  const navigationMenuModal = document.getElementById("modal-navigationMenu");
  const closeNavigationMenuModalBtn = document.getElementById(
    "modal-contact-navigationMenu"
  );

  if (navigationMenuBtn && navigationMenuModal && closeNavigationMenuModalBtn) {
    // Toggle navigation menu visibility when the hamburger icon is clicked
    navigationMenuBtn.addEventListener("click", (event) => {
      event.stopPropagation(); // Prevent the click from propagating to the document
      navigationMenuModal.classList.toggle("hide");
    });

    // Hide navigation menu when the close button is clicked
    closeNavigationMenuModalBtn.addEventListener("click", () => {
      navigationMenuModal.classList.add("hide");
    });

    // Hide navigation menu when any nav item is clicked
    const navItems = document.querySelectorAll(".navMenu-item");
    navItems.forEach((navItem) => {
      navItem.addEventListener("click", () => {
        navigationMenuModal.classList.add("hide");
      });
    });

    // Hide navigation menu when any nav item is clicked
    const toFormBtns = document.querySelectorAll(".toForm");
    toFormBtns.forEach((toFormBtn) => {
      toFormBtn.addEventListener("click", () => {
        navigationMenuModal.classList.add("hide");
      });
    });

    // Hide navigation menu when clicking outside of it
    document.addEventListener("click", (event) => {
      if (
        !navigationMenuModal.classList.contains("hide") &&
        !navigationMenuModal.contains(event.target as Node)
      ) {
        navigationMenuModal.classList.add("hide");
      }
    });
  } else {
    console.error("Navigation menu elements not found");
  }

  // Ensure Select2 is initialized only once
  if (!$("#fservice").hasClass("select2-initialized")) {
    $("#fservice").select2({
      placeholder: "Select services",
      allowClear: true,
      closeOnSelect: false, // Keep the dropdown open after selecting an item
      width: "100%",
    });

    // Add a class to indicate that Select2 has been initialized
    $("#fservice").addClass("select2-initialized");

    // Add and remove class for Select2 dropdown
    $("#fservice").on("select2:open", () => {
      const select2Selection = $("#fservice").data("select2").$selection;
      select2Selection.addClass("select2-open-border");
    });

    $("#fservice").on("select2:close", () => {
      const select2Selection = $("#fservice").data("select2").$selection;
      select2Selection.removeClass("select2-open-border");
    });
  }

  // Initialize Slick Slider
  const slider = $(".introduction-slider");
  if (slider.length && !slider.hasClass("slick-initialized")) {
    slider.slick({
      slidesToShow: 1,
      infinite: true,
      autoplay: false,
      autoplaySpeed: 1000,
      dots: true,
      arrows: false,
    });

    // Add a class to indicate that Slick has been initialized
    slider.addClass("slick-initialized");
  }

  // Smooth scroll to form-section on click
  const buttons = document.querySelectorAll(".toForm");
  const targetElement = document.getElementById("form-section");

  if (targetElement) {
    buttons.forEach((btn) => {
      btn.addEventListener("click", () => {
        smoothScroll(targetElement, 500);
      });
    });
  } else {
    console.error("Target element with ID form-section not found.");
  }

  // Skills slider implementation
  const btns = document.querySelectorAll(".slider-btn");
  const items = document.querySelectorAll(".skills-item");
  const skillSliderContainer = document.getElementById("skills-slider");

  if (skillSliderContainer) {
    btns.forEach((btn) => {
      btn.addEventListener("click", () => {
        const targetId = btn.getAttribute("data-target");
        if (!targetId) return;
        const targetItem = document.getElementById(targetId);
        if (!targetItem) return;
        items.forEach((item) => item.classList.remove("active"));
        btns.forEach((btn) => btn.classList.remove("active"));

        targetItem.classList.add("active");
        btn.classList.add("active");

        skillSliderContainer.scroll({
          left:
            targetItem.offsetLeft -
            (skillSliderContainer.clientWidth - targetItem.clientWidth) / 2,
          behavior: "smooth",
        });
      });
    });

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            items.forEach((item) => item.classList.remove("active"));
            btns.forEach((btn) => btn.classList.remove("active"));

            entry.target.classList.add("active");
            const activeButton = Array.from(btns).find(
              (btn) => btn.getAttribute("data-target") === entry.target.id
            );
            activeButton?.classList.add("active");
          }
        });
      },
      { threshold: 0.5 }
    );

    items.forEach((item) => observer.observe(item));

    const hammer = new Hammer(skillSliderContainer);
    hammer.on("swipeleft", () => {
      const activeItem = document.querySelector(
        ".skills-item.active"
      ) as HTMLElement;
      if (!activeItem) return;
      const nextItem =
        (activeItem.nextElementSibling as HTMLElement) || items[0];
      nextItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
      updateActiveState(nextItem, items, btns);
    });

    hammer.on("swiperight", () => {
      const activeItem = document.querySelector(".skills-item.active");
      if (!activeItem) return;
      const prevItem =
        (activeItem.previousElementSibling as HTMLElement) ||
        items[items.length - 1];
      prevItem.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
      updateActiveState(prevItem, items, btns);
    });
  }

  if (typeof customForm === "undefined") {
    console.error("customForm is not defined");
    return;
  } else {
    console.log("customForm is defined");
  }

  const overlay = document.getElementById("overlay");
  const popup = document.getElementById("popup");
  const closePopupBtn = document.getElementById("closePopupBtn");

  $(".showPopupBtn").click(function () {
    $.ajax({
      url: customForm.ajax_url, // Use localized ajax_url
      type: "POST",
      data: {
        action: "load_case_lamspa_content",
        nonce: customForm.nonce,
      },
      success: function (response) {
        // console.log("AJAX response:", response);
        $("#popupContent").html(response);
        if (overlay && popup) {
          overlay.style.display = "block";
          popup.style.display = "block";
          document.body.style.overflow = "hidden"; // Prevent main page from scrolling
        }
      },
      error: function (xhr, status, error) {
        console.error("There was a problem with the fetch operation:", error);
        const popupContent = document.getElementById("popupContent");
        if (popupContent) {
          popupContent.innerHTML = "Failed to load the case study.";
          if (overlay && popup) {
            overlay.style.display = "block";
            popup.style.display = "block";
            document.body.style.overflow = "hidden"; // Prevent main page from scrolling
          }
        }
      },
    });
  });

  if (closePopupBtn && overlay && popup) {
    const closePopup = () => {
      overlay.style.display = "none";
      popup.style.display = "none";
      document.body.style.overflow = "auto"; // Restore scrolling on main page
    };

    closePopupBtn.addEventListener("click", closePopup);
    overlay.addEventListener("click", closePopup);
  }

  // Handle form submission
  $("#form-section").on("submit", function (e) {
    e.preventDefault();

    var formData = $(this).serialize(); // This should include all form inputs along with the nonce

    $.ajax({
      type: "POST",
      url: customForm.ajax_url, // This should be provided via wp_localize_script
      data: formData,
      success: function (response) {
        if (response.success) {
          $("#thank-you-popup").show(); // Show a thank you popup on success
        } else {
          alert("Error: " + response.data.message); // Show error message from server
        }
      },
      error: function (xhr) {
        alert("Error: " + xhr.responseText);
      },
    });
  });

  // Event delegation for closing thank-you popup
  $(document).on(
    "click",
    ".thankModal-close, .thankModal-btn-back",
    function () {
      $("#thank-you-popup").hide();
    }
  );

  $("#thank-you-popup").on("click", function (e) {
    if (
      $(e.target).is("#thank-you-popup") ||
      $(e.target).hasClass("thankModal-close")
    ) {
      $(this).hide();
    }
  });

  // Close thank-you popup when clicking outside or on the close button
  $(document).on(
    "click",
    ".thankModal-close, .thankModal-btn-back",
    function () {
      $("#thank-you-popup").hide();
    }
  );

  // Event delegation for clicking outside the popup
  $("#thank-you-popup").on("click", function (e) {
    if (
      $(e.target).is("#thank-you-popup") ||
      $(e.target).hasClass("thankModal-close")
    ) {
      $(this).hide();
    }
  });
});
